@media (max-width: 608px) {
    html, body {
        max-width: 100%;
        overflow-x: hidden;
    }
}

html {
    background: transparent!important;
    background-color: transparent!important;
    -webkit-background-color: transparent!important;
}

.intro-container {
    max-width: 78% !important;
}

#content-container {
    padding-top: 10px;
    padding-bottom: 20px;
}

@media (min-width: 608px) {
    #content-container {
        padding-top: 50px!important;
        padding-left: 7%!important;
        padding-right: 7%!important;
        margin: 0 auto;
        max-width: 1648px;
        overflow-x: hidden;
    }
}

#content-container-team {
    padding-top: 50px;
    padding-bottom: 20px;
}

@media (min-width: 608px) {
    #content-container-team {
        margin: 0 auto;
        max-width: 1648px;
    }
}


.tenant-container {
    display: flex;
    flex-direction: column;
}

/* Set padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
}

/* Override the default bootstrap behavior where horizontal description lists
   will truncate terms that are too long to fit in the left column
*/
.dl-horizontal dt {
    white-space: normal;
}

/* .intro-container {
    max-width: 65% !important;
} */

/* Set width on the form input elements since they're 100% wide by default */
input,
select,
textarea {
    max-width: 280px;
}