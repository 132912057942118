@font-face {
    font-family: AirbnbCereal-Black;
    src: url('../fonts/AirbnbCereal-Black.ttf');
}

@font-face {
    font-family: AirbnbCereal-Bold;
    src: url('../fonts/AirbnbCereal-Bold.ttf');
}

@font-face {
    font-family: AirbnbCereal-Book;
    src: url('../fonts/AirbnbCereal-Book.ttf');
}

@font-face {
    font-family: AirbnbCereal-ExtraBold;
    src: url('../fonts/AirbnbCereal-ExtraBold.ttf');
}

@font-face {
    font-family: AirbnbCereal-Light;
    src: url('../fonts/AirbnbCereal-Light.ttf');
}

@font-face {
    font-family: AirbnbCereal-Medium;
    src: url('../fonts/AirbnbCereal-Medium.ttf');
}

body {
    background-color: #f2f2f2;
}

h3 {
    font-family: AirbnbCereal-Medium;
    font-size: 48px;
    color: #333;
}

small {
    font-family: AirbnbCereal-Medium;
    font-size: 14px;
    color: #666;
}

a {
    text-decoration: none;
    transition: all 0.3s;
}

a:hover {
    text-decoration: none;
}

/*ȥ��input��ȡ����ʱ�߿����ӰЧ��*/
/*input:focus,textarea:focus{outline:none;-webkit-appearance:none;-webkit-box-shadow:none;box-shadow:none!important;}*/
/*ȥ�� input type=number �Ҳ�����¼�ͷ*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"] {
    -moz-appearance: textfield;
}

/* ѡ����ʷ��¼��������ɫ�ͱ�����ɫ */
input:-webkit-autofill {
    -webkit-animation: autofill-fix 1s infinite !important;
    /* ѡ����ʷ��¼��������ɫ*/
    -webkit-text-fill-color: #666;
    -webkit-transition: background-color 50000s ease-in-out 0s !important;
    transition: background-color 50000s ease-in-out 0s !important;
    background-color: transparent !important;
    background-image: none !important;
    /* ѡ����ʷ��¼�ı�����ɫ */
    -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
}

#ajax-loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    display: none;
}

#ajax-loader img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.main-box {
    width: 100%;
    max-width: 100%;
    padding: 0 auto;
    margin: 0 auto;
}

.btn {
    transition: all 0.3s;
}

#top-nav {
    height: 62px;
    font-family: AirbnbCereal-Bold;
    font-size: 14px;
    color: #333333;
}

.navbar-default {
    background-color: transparent;
    border: 0;
}

.navbar-brand {
    padding: 2px;
    opacity: 0.87;
}

.navbar-nav>li>a {
    color: #333333 !important;
    background-color: transparent !important;
    padding: 0 21px 0 21px;
    line-height: 62px;
}

.navbar-nav>li>a>i {
    height: 12px;
    width: 12px;
    background-color: transparent;
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    transition: background-color 0.3s;
}

.navbar-nav>li>#nav-item1>i {
    left: 66px;
    top: 32px;
}

.navbar-nav>li>#nav-item2>i {
    left: 18px;
    top: 20px;
}

.navbar-nav>li>#nav-item3>i {
    left: 22px;
    top: 30px;
}

.navbar-nav>li>#nav-item4>i {
    left: 68px;
    top: 20px;
}

#mainTab>li>#nav-item1>i {
    left: 66px;
    top: 3px;
}

#mainTab>li>#nav-item2>i {
    left: 66px;
    top: 33px;
}

#mainTab>li>#nav-item3>i {
    left: 80px;
    top: 3px;
}

#mainTab>li>#nav-item4>i {
    left: 76px;
    top: 33px;
}

#mainTab>li>#nav-item5>i {
    left: 85px;
    top: 6px;
}

#mainTab>li>#nav-item6>i {
    left: 86px;
    top: 33px;
}

@media screen and (max-width: 900px) {
    .navbar-nav>li>a {
        padding: 0 11px 0 11px;
    }
}

.navbar-nav>li>a:hover {
    color: #333333 !important;
    background-color: transparent !important;
}

.navbar-nav>li>a:hover>i {
    background-color: #ff5a62;
}

.navbar-nav .active>a>i {
    background-color: #ff5a62;
}

#top-link {
    margin-left: 93px;
    margin-top: 12px
}

@media screen and (max-width: 1080px) {
    #top-link {
        display: none;
    }
}

.navbar-toggle {
    border: 0;
    margin-top: 4px;
    margin-right: 26px;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #5f4b8b;
    width: 46px;
    height: 4px;
}

.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 12px;
}

#banner-topborder {
    border-bottom: 2px solid #5f4b8b;
}

#banner-container {
    background: url('../Img/main_pic_keyfangzi1200px.png') no-repeat center 0;
    ;
    min-height: 543px;
    margin-top: -2px;

    @media (min-width: 768px) {
        margin-left:

            -4%;
        margin-right:

            -7%;
    }

}

#banner-container h3 {
    color: #333333;
    font-family: AirbnbCereal-Book;
    font-size: 44px;
    margin-top: 162px;
}

#banner-container p {
    color: #666666;
    font-family: AirbnbCereal-Book;
    font-size: 22px;
    margin-top: 28px;
}

#banner-container small {
    color: #666666;
    font-size: 12px;
    font-family: AirbnbCereal-Light;
    margin-top: 24px;
    display: block;
}

#banner-btns {
    margin-top: 30px;
}

#banner-btns .btn {
    font-family: AirbnbCereal-Medium;
    color: #5f4b8b;
    font-size: 16px;
    height: 52px;
    line-height: 34px;
    padding: 6px 18px 6px 18px;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #5f4b8b;
    border-radius: 10px;
}

#banner-btns .btn i {
    margin-bottom: -6px;
}

#banner-btns .btn span {
    vertical-align: middle;
}

#banner-btns .btn:hover {
    background-color: #5f4b8b;
    color: #fefefe;
}

#banner-btns #find-tenant i {
    background: url('../Img/main_btn_search.png') no-repeat center;
    background-size: auto 100%;
    width: 21px;
    height: 21px;
    display: inline-block;
    margin-right: 26px;
}

#banner-btns #find-tenant:hover>i {
    background: url('../Img/main_btn_search_opt.png') no-repeat center;
    background-size: auto 100%;
}

#banner-btns #have-tenant i {
    background: url('../Img/main_btn_duigou.png') no-repeat center;
    width: 21px;
    height: 21px;
    display: inline-block;
    margin-right: 22px;
    margin-bottom: -6px;
}

#banner-btns #have-tenant:hover>i {
    background: url('../Img/main_btn_duigou_opt.png') no-repeat center;
}

#main-container {
    margin-top: 140px;
    max-width: 1550px;
}

#main-container h3 {
    font-family: AirbnbCereal-Medium;
    font-size: 30px;
    color: #333;
    margin-bottom: 16px;
}

#main-container p {
    color: #999;
    font-family: AirbnbCereal-Book;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 24px;
}

#main-container .phone-main-pic {
    display: none;
}

#mainTab {
    background-color: white;
    border: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

#mainTab li {
    font-family: AirbnbCereal-Bold;
    color: #333;
    font-size: 14px;
    width: 16.6666%;
    height: 52px;
    margin-top: 20px;
    border-left: 2px solid #f2f2f2;
}

#mainTab li:first-child {
    border: 0;
}

#mainTab li a {
    color: #666;
    border: 0;
    z-index: 2;
    padding: 5px 15px;
    cursor: pointer;
}

#mainTab li a:hover {
    background-color: transparent;
    border: 0;
    font-family: AirbnbCereal-Bold;
    font-size: 14px;
    color: #333;
}

#mainTab li>a>i {
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    transition: background-color 0.3s;
    width: 14px;
    height: 14px;
    background-color: transparent;
    left: 116px;
    top: 2px;
}

#mainTab li>a:hover>i {
    background-color: #ff5a62;
}

#mainTab .active {
    font-family: AirbnbCereal-Bold;
    font-size: 14px;
    color: #333;
}

#mainTab .active a {
    color: #333;
    border: 0;
}

#mainTab .active a>i {
    background-color: #ff5a62;
}

#mainTab-box {
    padding: 26px 24px;
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

#mainTabContent {}

#mainTabContent .tab-pane {}

#mainTabContent #tab1 {
    background: url('../Img/NewImg/main_pic1.jpg') no-repeat center 0;
    background-size: cover;
    overflow-x: hidden;
    overflow-y: auto;
}

#mainTabContent #tab2 {
    background: url('../Img/NewImg/main_pic7.jpg') no-repeat center 0;
    background-size: cover;
    overflow-x: hidden;
    overflow-y: auto;
}

#mainTabContent #tab3 {
    background: url('../Img/NewImg/main_pic10.jpg') no-repeat center 0;
    background-size: cover;
    overflow-x: hidden;
    overflow-y: auto;
}

#mainTabContent #tab4 {
    background: url('../Img/NewImg/reason_pic.jpg') no-repeat center 0;
    background-size: cover;
    overflow-x: hidden;
    overflow-y: auto;
}

#mainTabContent #tab5 {
    background: url('../Img/NewImg/main_pic5.jpg') no-repeat center 0;
    background-size: cover;
    overflow-x: hidden;
    overflow-y: auto;
}

#mainTabContent #tab6 {
    background: url('../Img/NewImg/pro_pic5.jpg') no-repeat center 0;
    background-size: cover;
    overflow-x: hidden;
    overflow-y: auto;
}

#mainTabContent .tab-pane .tab-pane-box {
    margin: 102px 60px;
    background-color: rgba(255, 255, 255, 0.84);
    border-radius: 10px;
    width: 342px;
    padding: 40px 38px 52px 40px;
}



#mainTabContent .tab-pane h4 {
    font-family: AirbnbCereal-Medium;
    font-size: 26px;
    color: #5f4b8b;
}

#mainTabContent .tab-pane h5 {
    font-family: AirbnbCereal-Medium;
    font-size: 26px;
    color: #5f4b8b;
}

#mainTabContent .tab-pane p {
    font-family: AirbnbCereal-Book;
    font-size: 16px;
    color: #333;
    margin-top: 36px;
    margin-bottom: 0px;
}

#ad-container-title {
    text-align: center;
    font: 28px AirbnbCereal-Medium;
    color: #333;
    margin-bottom: 40px;
}

#main-ad-container-mobile {
    max-width: 1048px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
}

#main-ad-container-mobile a {
    margin: 15px auto;
    flex: 1;
    width: 140px;
    scale: 1.3
}

#main-ad-container-mobile-2 {
    max-width: 1048px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

#main-ad-container-mobile-2 img {
    margin: 0 auto;
    flex: 0 auto;
    width: 140px;
    scale: 1.3
}

#main-ad-container {
    max-width: 1048px;
}

#main-ad-container a {
    height: 100px;
    align-content: end;
    text-align: center;
}

.partner-logo {
    width: 140px;
    margin-right: 20px;
    scale: 1.2
}

#realestate-logo {
    width: 140px;
    margin-right: 20px;
    scale: 1.5
}

#propertyme-logo {
    width: 140px;
    margin-left: 25px;
    scale: 1.5
}

#phone-container {
    margin-top: 32px;
    max-width: 1400px;
}

#phoneTab {
    margin-top: 320px;
    margin-left: 20px;
}

@media screen and (max-width: 992px) {
    #phoneTab li {
        float: left;
        width: 25%;
    }
}

#phoneTab a {
    font-family: AirbnbCereal-Book;
    font-size: 20px;
    color: #999;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
}

#phoneTab a:hover {
    color: #333;
    background-color: transparent;
}

#phoneTab span {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    display: inline-block;
    text-align: center;
}

#phoneTab span i {
    width: 12px;
    height: 12px;
    background-color: #999;
    display: inline-block;
    border-radius: 50%;
    margin-bottom: 1px;
    transition: 0.3s;
}

#phoneTab a:hover>span>i {
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 3.5px solid #ff5a62;
    border-radius: 50%;
    margin-bottom: -4px;
}

#phoneTab .active span i {
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: 3.5px solid #ff5a62;
    border-radius: 50%;
    margin-bottom: -4px;
}

#phoneTab .active a {
    color: #333;
    background-color: transparent;
}

#phoneTab .active .mobile-tab-item {
    opacity: 1 !important;
}

#phone-container .mobile-screen {
    position: relative;
    display: inline-block;
}

#phone-container .mobile-screen .mobile-bg {
    width: 271px;
    height: 541px;
}

#phone-container .mobile-screen .mobile-content {
    position: absolute;
    top: 14px;
    left: 14px;
    z-index: -1;
    width: 243px;
    height: 512px;
}

#phone-pointer {
    margin-top: 36px;
}

#phone-pointer li i {
    background-color: #dbdbdb;
    height: 4px;
    margin-left: 3px;
    margin-right: 3px;
    display: block;
}

#phone-pointer .active i {
    background-color: #ff5a62;
}

#phone-right {
    padding-left: 24px;
    padding-right: 0;
    float: right;
    display: flex;
    flex-direction: column;
    height: 541px;
    justify-content: center;
}

#phone-right h4 {
    font-family: AirbnbCereal-Medium;
    font-size: 30px;
    color: #333;
    line-height: 54px;
    padding-right: 10px;
}

#phone-right img {
    width: 52px;
    height: 49px;
    margin-top: 5px;
    margin-bottom: 15px;
}

#phone-right p {
    font-family: AirbnbCereal-Book;
    font-size: 16px;
    color: #333;
    line-height: 26px;
    margin-top: 40px;
}

#phone-right p {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    *white-space: normal;
}

#leasing-container {
    margin-top: 100px;
    margin-bottom: 95px;
}

#leasing-container h3 {
    font-family: AirbnbCereal-Medium;
    font-size: 28px;
    color: #333;
    margin-bottom: 64px;
}

#leasing-container i {
    margin: 0 auto;
}

#leasing-container ul li p {
    font-family: AirbnbCereal-Bold;
    font-size: 16px;
    color: #333;
    margin-top: 28px;
}

#leasing-container #main-phone {
    background: url('../Img/main_phone.png') no-repeat center;
    width: 45px;
    height: 44px;
    display: block;
}

#leasing-container #main-phone:hover {
    background: url('../Img/main_phone_opt.png') no-repeat center;
    width: 45px;
    height: 44px;
    display: block;
    background-size: auto 100%;
}

/* #leasing-container #main-wechat{background:url('../Img/main_wechat.png') no-repeat center;width:57px;height:44px;display:block;} */
/* #leasing-container #main-wechat:hover{background:url('../Img/main_wechat_opt.png') no-repeat center;width:57px;height:44px;display:block;background-size:auto 100%;} */
#leasing-container #main-email {
    background: url('../Img/main_email.png') no-repeat center;
    width: 53px;
    height: 39px;
    display: block;
}

#leasing-container #main-email:hover {
    background: url('../Img/main_email_opt.png') no-repeat center;
    width: 53px;
    height: 39px;
    display: block;
    background-size: auto 100%;
}

#leasing-container #main-visitus {
    background: url('../Img/main_visitus.png') no-repeat center;
    width: 45px;
    height: 47px;
    display: block;
}

#leasing-container #main-visitus:hover {
    background: url('../Img/main_visitus_opt.png') no-repeat center;
    width: 45px;
    height: 47px;
    display: block;
    background-size: auto 100%;
}

#leasing-container ul li>div {
    position: relative;
}

#leasing-container #qr-code {
    display: none;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    margin-top: -2px;
    z-index: 1;
}

#leasing-container #qr-code>img {
    width: 116px;
}

footer {
    background-color: #5F4B8B;
}

footer #footer-nav-title {
    border-bottom: 1px solid #f2f2f2;
    margin-top: 66px;
    margin-bottom: 0;
}

footer #footer-nav-title ul {
    margin-bottom: 0;
}

footer .footer-nav {
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 118px;
}

footer .footer-nav ul {
    margin: 0 auto;
    margin-left: 40px;
}

footer .footer-nav li {
    width: 25%;
    float: left;
}

footer .footer-nav li:nth-child(1) {
    width: 25%;
    float: left;
}

footer .footer-nav li:nth-child(2) {
    width: 25%;
    float: left;
}

footer .footer-nav li:nth-child(3) {
    width: 20%;
    float: left;
}

footer .footer-nav li:nth-child(4) {
    width: 30%;
    float: left;
}

footer .footer-nav div>a {
    font-family: AirbnbCereal-Bold;
    font-size: 16px;
    color: #eee;
    margin-bottom: 26px;
    margin-top: 0;
    display: none;
}

footer .footer-nav a {
    font-family: AirbnbCereal-Medium;
    font-size: 14px;
    color: #eee;
    display: block;
    margin-top: 24px;
}

footer .footer-nav a:hover {
    color: #ff5a62;
}

#office-section .branch-title {
    font-family: AirbnbCereal-Bold;
    font-size: 16px;
    color: #eee;
    margin-top: 24px;
    margin-bottom: 26px;
}

#office-section div {
    margin-top: 24px;
}

#office-section p {
    font-family: AirbnbCereal-Medium;
    font-size: 14px;
    color: #eee;
    display: block;
    margin: 0 0 0 10px!important;
}


footer #footer-nav-title div>a {
    display: block;
}

footer #footer-nav-title a:hover {
    color: #eee;
}

/* footer #tail-logo {
    background: url('../Img/NewImg/new_logo.png') no-repeat center;
    width: 209px;
    height: 63px;
    display: block;
    color: #000;
    margin: 0 auto;
} */

footer small {
    margin-top: 28px;
    margin-bottom: 32px;
    display: block;
    font-family: AirbnbCereal-Book;
    font-size: 12px;
    color: #f2f2f2;
}

footer #tail-link {
    margin-top: 10px;
    margin-bottom: 10px;
}

footer #tail-link a {
    margin-left: 12px;
    margin-right: 12px;
    display: block;
}

footer #tail-link #youtube-logo {
    background: url('../Img/footer_youtube.png') no-repeat center;
    width: 34px;
    height: 34px;
    display: block;
}


footer #tail-link #ins-logo {
    background: url('../Img/footer_ins.png') no-repeat center;
    width: 34px;
    height: 34px;
    display: block;
}

/*property*/
#property-header {
    margin-top: 110px;
}

#property-header h3 {
    font-family: AirbnbCereal-Book;
    font-size: 44px;
    color: #333;
}

#property-header small {
    font-family: AirbnbCereal-Book;
    font-size: 16px;
    color: #666;
    margin-top: 26px;
    display: block;
}

#property-header .input-group {
    height: 58px;
    margin: 0 auto;
    margin-top: 24px;
    width: 598px;
    margin-bottom: 35px;
}

#property-header .input-group #location {
    height: 58px;
    width: 392px;
    font: 16px AirbnbCereal-Book;
    color: #666;
    border: 2px solid #5f4b8b;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    min-width: 100%;
}

#property-header .input-group #location:focus {
    outline: none;
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none !important;
}

#property-header .input-group:hover #location {
    border: 2px solid #ff5a62;
}

#property-header .input-group #submit {
    height: 58px;
    width: 206px;
    font: 16px AirbnbCereal-Medium;
    color: #fefefe;
    background-color: #5f4b8b;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

#property-header .input-group #submit {
    outline: none;
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#property-header .input-group:hover #submit {
    background-color: #ff5a62;
}

#property-header ul {
    font: 16px AirbnbCereal-Book;
    color: #666;
}

#property-header ul li {
    line-height: 19px;
    height: 19px;
    margin: 0 14px 0 14px;
}

#property-header ul li img {
    margin-right: 7px;
    height: 19px;
    margin-bottom: 3px;
}

#property-header .text-danger {
    font: 18px AirbnbCereal-Book;
    color: #ff5a62;
}

#property-process {
    margin-top: 120px;
}

#property-process h3 {
    font-family: AirbnbCereal-Medium;
    font-size: 30px;
    color: #333;
}

#property-process small {
    font-family: AirbnbCereal-Book;
    font-size: 14px;
    color: #666;
    margin-top: 14px;
    margin-bottom: 50px;
    display: block;
}

#property-process .phone-main-pic {
    display: none;
}

#property-process-container {
    background-color: white;
    border-radius: 5px;
}

#property-process-container .mainTab-pointer {
    display: none;
}

#property-process-container>div>.btn {
    display: none;
}

/* #property-process-nav{margin-top:30px;} */
#property-process-nav a {
    color: #999;
    padding: 15px 10px 5px 15px;
    position: relative;
    z-index: 2;
    cursor: pointer;
}

@media (min-width: 768px) {
    /* #property-process-nav a{margin-bottom: 19px;} */
}

#property-process-nav a:hover {
    background-color: transparent;
}

#property-process-nav a>i {
    position: absolute;
    background-color: transparent;
    height: 20px;
    width: 20px;
    left: 6px;
    top: 28px;
    border-radius: 50%;
    z-index: -1;
    transition: background-color 0.3s;
}

#property-process-nav li:last-child>a>i {
    top: 12px;
}

#property-process-nav a:hover>i {
    background-color: #ff5a62;
}

#property-process-nav .active a {
    color: #333333ce;
    background: 0;
}

#property-process-nav .active a>i {
    background-color: #ff5a62;
}

#property-process-nav h4 {
    font-family: AirbnbCereal-Bold;
    font-size: 20px;
    margin-top: 16px;
    margin-bottom: 6px;
}

#property-process-nav li:last-child>a>h4 {
    margin-top: 0;
}

#property-process-nav p {
    font-family: AirbnbCereal-Bold;
    font-size: 16px;
}

#property-process-nav-box {
    width: 25%;
}

#property-process-box {
    width: 75%;
    padding: 20px 20px 20px 0;
    height: 100%;
}

#property-process-box .tab-pane {
    background: url('../Img/pro_process_bg.png') no-repeat center 0;
    background-size: cover;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5px;
    height: 82vh
}

#property-process-box #process1 {
    background: url('../Img/NewImg/pro_pic1.jpg') no-repeat center 0;
    background-size: cover;
    height: 100%;
}

#property-process-box #process2 {
    background: url('../Img/NewImg/pro_pic7.jpg') no-repeat center 0;
    background-size: cover;
    height: 100%;
}

#property-process-box #process3 {
    background: url('../Img/NewImg/pro_pic4.jpg') no-repeat center 0;
    background-size: cover;
    height: 100%;
}

#property-process-box #process4 {
    background: url('../Img/NewImg/main_pic6.jpg') no-repeat center 0;
    background-size: cover;
    height: 100%;
}

#property-process-box #process5 {
    background: url('../Img/NewImg/pro_pic8.jpg') no-repeat center 0;
    background-size: cover;
    height: 100%;
}

#property-process-box #process6 {
    background: url('../Img/NewImg/pro_pic9.jpg') no-repeat center 0;
    background-size: cover;
    height: 100%;
}

#property-process-box .tab-pane .tab-pane-box {
    margin: 40px 40px 59px 40px;
    background-color: rgba(255, 255, 255, 0.84);
    border-radius: 5px;
    width: 329px;
    height: 477px;
    padding: 50px 37px 50px 37px;
    position: relative;
}

#tab-pane-box-2 {
    float: right;
}

#tab-pane-box-6 {
    float: right;
}

#property-process-box .tab-pane h4 {
    font-family: AirbnbCereal-Medium;
    font-size: 26px;
    color: #5f4b8b;
}

#property-process-box .tab-pane p {
    font-family: AirbnbCereal-Book;
    font-size: 16px;
    line-height: 28px;
    color: #333;
    height: 281px;
    margin-top: 30px;
}

#property-process-box .tab-pane .text-center {
    position: absolute;
    bottom: 40px;
    left: 10%;
}

#property-process-box .tab-pane .btn {
    width: 261px;
    height: 55px;
    font: 16px AirbnbCereal-Medium;
    color: white;
    background-color: #5f4b8b;
    border-radius: 10px;
    padding-top: 15px;
}

#property-process-box .tab-pane .btn:hover {
    background-color: #ff5a62;
}

#property-service {
    margin-top: 112px;
}

#property-service h3 {
    font-family: AirbnbCereal-Medium;
    font-size: 30px;
    color: #333;
}

#property-service small {
    font-family: AirbnbCereal-Book;
    font-size: 14px;
    color: #666;
    margin: 30px 90px 32px 90px;
    display: block;
}

#property-service-box {
    border-radius: 5px;
    padding: 0;
}

#property-service-box ul {
    margin: 0 -21px;
}

#property-service-box .property-service-bg {
    margin: 18px 21px;
    padding: 34px 30px 34px 30px;
    background-color: white;
    border-radius: 5px;
}

#property-service-box h5 {
    font: 16px AirbnbCereal-Book;
    color: #080808;
    height: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 24px 0 0px 0;
}

#property-service-box p {
    font: 16px AirbnbCereal-Book;
    color: #080808;
    display: none;
}

#property-video {
    margin-top: 74px;
}

#property-video h3 {
    font-family: AirbnbCereal-Medium;
    font-size: 30px;
    color: #333;
    padding-bottom: 50px;
}

#property-video p {
    font-family: AirbnbCereal-Book;
    font-size: 16px;
    color: #333;
    line-height: 30px;
    padding-right: 30px;
    padding-bottom: 40px;
    margin-bottom: 0;
}

#property-video-box {
    padding: 0 10px 10px 10px;
    float: right;
    display: flex;
    justify-content: end;
    overflow: hidden;
}

#property-video-box img {
    width: 100%;
    height: 100%;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

#property-video-text {
    padding-left: 0;
}

#property-video-66 img {
    width: 53px;
}

#property-reason {
    margin-top: 102px;
    margin-bottom: 110px;
}

#property-reason h3 {
    font-size: 30px;
    margin-bottom: 14px;
}

#property-reason small {
    display: block;
    font: 14px AirbnbCereal-Book;
    color: #666;
}

#property-reason-box {
    margin-top: 56px;
    background-color: #fff;
    position: relative;
}

#property-reason-box #property-reason-left {
    width: 31.5%;
    float: left;
    /* background: rgba(149, 129, 188, 0.6) url('../Img/pro_reason_bg.png') no-repeat; */
    background: rgba(149, 129, 188, 0.6) url('../Img/NewImg/reason_new.jpg') no-repeat;
    height: 100%;
    position: absolute;
    top: 0;
    background-size: cover;
}

#property-reason-box #property-reason-left p {
    padding: 380px 36px;
    font: 18px AirbnbCereal-Medium;
    color: #fff;
    background: rgba(149, 129, 188, 0.5);
    width: 100%;
    height: 100%;
}

#property-reason-box #property-reason-right {
    width: 68.5%;
    float: right;
    padding-bottom: 40px;
}

#property-reason-box li {
    padding: 30px 44px 0px 54px;
}

#property-reason-box .property-reason-bg {}

#property-reason-box .property-reason-bg img {
    height: 40px;
}

#property-reason-box h5 {
    font: 18px AirbnbCereal-Medium;
    color: #080808;
    margin: 24px 0 25px 0;
}

#property-reason-box p {
    font: 16px AirbnbCereal-Book;
    color: #080808;
    min-height: 128px;
    line-height: 28px;
    margin-bottom: 0;
}

#property-tailer {
    margin-top: 46px;
    margin-bottom: 46px;
    display: none;
}

/*about*/
#about-header {
    margin-top: 36px;
    margin-bottom: 78px;
    background: url('../Img/about_main_chatu.png') no-repeat center;
    height: 191px;
}

#about-header p {
    font: 18px AirbnbCereal-Book;
    color: #333;
    line-height: 24px;
    max-width: 1048px;
    margin: 0 auto;
    text-align: left!important;
}

#about-header h3 {
    font: 44px AirbnbCereal-Book;
    color: #333;
    padding-top: 52px;
    margin-bottom: 14px;
}

#about-header small {
    font: 18px AirbnbCereal-Book;
    color: #666;
}

.about-box {
    max-width: 1048px;
    margin-bottom: 66px;
}

/* .about-box .about-pic {
    padding-left: 0;
} */

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.about-pic {
    padding-right: 0;
}

.about-left {
    visibility: hidden;
}

.about-right {
    visibility: hidden;
}


.fadeIn {
    animation-name: fadeIn;
    animation-duration: 2s;
    visibility: visible !important;
}

.about-left .about-pic {
    padding-left: 0;
}

@media screen and (min-width: 1000px) {
    .about-right .about-pic {
        padding-right: 0;
        float: right;
    }

    .about-left .about-content {
        padding-left: 60px;
    }
}


@media screen and (max-width: 1000px) {
    .about-content {
        padding-left: 0 !important;
    }

    .about-pic {
        padding-left: 0 !important;
    }
}

.about-right .about-content {
    padding-left: 0px;
}

.about-box .about-content {
    /* padding-left: 23px; */
    padding-right: 0;
}

.about-box h4 {
    font: 30px AirbnbCereal-Medium;
    color: #333;
    margin-top: 37px;
    margin-bottom: 22px;
}

.about-box h4 img {
    margin-top: -8px;
    margin-right: 20px;
}

.about-box p {
    font: 18px AirbnbCereal-Book;
    color: #333;
    line-height: 24px;
    margin-bottom: 0;
}

.about-box ul {
    font: 18px AirbnbCereal-Book;
    color: #333;
}

.about-box ul li {}

#about-first-box {
    margin-top: 60px;
    /* margin-bottom: 88px; */
}

#about-first-box .about-content {
    /* padding: 0 20px; */
}

#about-first-box h4 {
    margin-top: 42px;
    margin-bottom: 32px;
}

#about-first-box ul {
    margin-bottom: 0;
}

#google-map {
    margin-top: 104px;
    margin-bottom: 86px;
    max-width: 1048px;
}

#google-map h4 {
    font: 30px AirbnbCereal-Medium;
    color: #333;
    margin-bottom: 16px;
}

#google-map small {
    font: 18px AirbnbCereal-Book;
    color: #666;
    max-width: 700px;
    display: inline-block;
}

#google-map img {
    width: 100%;
    margin-top: 40px;
}

/*contact*/
#contact-header {
    background: url('../Img/contact_main_chatu.png') no-repeat center 0;
    margin-top: 0;
    min-height: 190px;
}

#contact-header h3 {
    margin-top: 0;
    padding-top: 42px;
    font: 44px AirbnbCereal-Book;
}

#contact-header small {
    font: 18px AirbnbCereal-Book;
    margin-top: 30px;
    display: block;
}

#contact-touch {
    margin-top: 20px;
    background: white;
    border-radius: 10px;
    padding: 32px 0 32px 0;
    max-width: 700px;
}

#contact-touch h3 {
    font: 30px AirbnbCereal-Medium;
    margin-top: 0;
    margin-bottom: 20px;
}

#contact-touch small {
    font: 20px AirbnbCereal-Book;
    display: block;
    margin-bottom: 40px;
}

#contact-touch .col-md-6,
.col-md-12 {
    padding: 0 7px 0 7px;
}

#contact-touch .form-horizontal {
    width: 80.8%;
    margin: 0 auto;
}

#contact-touch .form-group {
    margin-bottom: 16px;
}

#contact-touch .text-danger {
    font: 18px AirbnbCereal-Book;
    color: #ff5a62;
}

#contact-touch #sel-role-box {
    margin-bottom: 32px;
}

#contact-touch input {
    height: 52px;
    padding: 18px 24px;
    font: 16px AirbnbCereal-Book;
    color: #333;
    border: 2px solid rgba(149, 129, 188, 0.2);
    border-radius: 5px;
    min-width: 100%;
}

#contact-touch input::-webkit-input-placeholder {
    font: 16px AirbnbCereal-Light;
    color: #999;
}

#contact-touch input::-moz-placeholder {
    font: 16px AirbnbCereal-Light;
    color: #999;
}

#contact-touch input:-ms-input-placeholder {
    font: 16px AirbnbCereal-Light;
    color: #999;
}

#contact-touch input:-moz-placeholder {
    font: 16px AirbnbCereal-Light;
    color: #999;
}

#contact-touch textarea {
    resize: none;
    height: 120px;
    padding: 24px;
    font: 16px AirbnbCereal-Book;
    color: #333;
    border: 2px solid rgba(149, 129, 188, 0.2);
    border-radius: 5px;
    min-width: 100%
}

#contact-touch textarea::-webkit-input-placeholder {
    font: 16px AirbnbCereal-Light;
    color: #999;
}

#contact-touch textarea::-moz-placeholder {
    font: 16px AirbnbCereal-Light;
    color: #999;
}

#contact-touch textarea:-ms-input-placeholder {
    font: 16px AirbnbCereal-Light;
    color: #999;
}

#contact-touch textarea:-moz-placeholder {
    font: 16px AirbnbCereal-Light;
    color: #999;
}

#contact-touch input:focus {
    border: 2px solid #5f4b8b;
    border-radius: 5px;
}

#contact-touch textarea:focus {
    border: 2px solid #5f4b8b;
    border-radius: 5px;
}

.sel-role {
    text-align: center;
    cursor: pointer;
}

.sel-role p {
    font: 18px AirbnbCereal-Medium;
    color: #333;
    margin-top: 6px;
}

.sel-role i {
    width: 76px;
    height: 76px;
    display: inline-block;
}

.sel-role #contact-landlord {
    background: url('../Img/contact_main_landlord.png') no-repeat center;
}

.sel-role-checked #contact-landlord {
    background: url('../Img/contact_main_landlord_opt.png');
}

.sel-role #contact-tenant {
    background: url('../Img/contact_main_tenant.png') no-repeat center;
}

.sel-role-checked #contact-tenant {
    background: url('../Img/contact_main_tenant_opt.png');
}

.sel-role #contact-partner {
    background: url('../Img/contact_main_partner.png') no-repeat center 0;
}

.sel-role-checked #contact-partner {
    background: url('../Img/contact_main_partner_opt.png');
}

#contact-touch .sel-exist {
    height: 52px;
    width: 244px;
    display: block;
    cursor: pointer;
    font: 18px AirbnbCereal-Light;
    color: #333;
    background: rgba(149, 129, 188, 0.2);
    border-radius: 26px;
}

#contact-touch .sel-exist {
    margin: 0 auto;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 30px;
    transition: all 0.3s;
}

#contact-touch .sel-exist .pre {
    background: url('../Img/contact_guanbi.png') no-repeat;
    background-size: cover;
    width: 40px;
    height: 41px;
    display: inline-block;
    vertical-align: middle;
    margin-left: -32px;
    margin-bottom: 2px;
}

#contact-touch .sel-exist span {
    padding: 14px 5px;
    display: inline-block;
}

#contact-touch .sel-exist-checked {
    font: 18px AirbnbCereal-Medium;
    color: #ff5a62;
    background: rgba(255, 90, 98, 0.2);
    padding: 0;
    border: 0;
}

#contact-touch .sel-exist-checked .pre {
    display: none;
}

#contact-touch .sel-exist-checked .post {
    background: url('../Img/contact_duigou.png') no-repeat;
    background-size: cover;
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    margin-right: -22px;
    margin-bottom: 3px;
}

#contact-touch input[type=submit] {
    padding: 24px;
    font: 24px AirbnbCereal-Bold;
    color: #fff;
    background: #5f4b8b;
}

#contact-touch input[type=submit]:hover {
    background: #ff5a62;
}

#contact-touch a {
    height: 52px;
    width: 462px;
    font: 18px AirbnbCereal-Bold;
    color: #fff;
    background: #5f4b8b;
    line-height: 44px;
}

#contact-touch a {
    outline: none;
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
}

#contact-touch a:hover {
    background: #ff5a62;
}

#contact-touch a i {
    background: url('../Img/contact_main1_btn_contact.png');
    background-size: cover;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin-left: 18px;
    vertical-align: middle;
    margin-bottom: 4px;
}

#contact-info-1 {
    margin-top: 76px;
    margin-bottom: 120px;
    background: white;
    border-radius: 10px;
    max-width: 1048px;
}

#contact-map {
    width: 52.67%;
    height: 532px;
    float: left;
}

#contact-map iframe {
    width: 100%;
    height: 100%;
}

#contact-text {
    width: 47.32%;
    float: left;
    padding-top: 40px;
    padding-left: 60px;
}

#contact-text dt {
    margin-top: 48px;
    font: 18px AirbnbCereal-Bold;
    line-height: 18px;
    color: #333;
}

#contact-text dd {
    margin-top: 12px;
    font: 16px AirbnbCereal-Bold;
    line-height: 28px;
    color: #666;
}

/*blog*/
#blog-header {
    background: url('../Img/blog_title_bg.png') no-repeat center 0;
    margin-top: 42px;
    height: 180px;
    margin-bottom: 116px;
}

#blog-header h3 {
    padding-top: 46px;
    font: 44px AirbnbCereal-Book;
}

#blog-header small {
    font: 18px AirbnbCereal-Book;
    color: #666;
    margin-top: 14px;
    display: block;
}

#blog-slider {
    max-width: 1048px;
    margin-bottom: 30px;
}

#blog-slider .carousel-indicators {
    bottom: 106px;
}

#blog-slider .carousel-indicators li {
    width: 14px;
    height: 14px;
    background-color: rgba(0, 0, 0, 0.5);
    border: 0;
    margin: 0 20px;
}

#blog-slider .carousel-indicators .active {
    width: 14px;
    height: 14px;
    background-color: #ff5a62;
    margin: 0 20px;
}

#blog-slider .carousel-caption {
    bottom: 0;
    height: 106px;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.5);
    left: 0;
    right: 0;
}

#blog-slider .carousel-caption a {
    display: block;
    width: 100%;
    height: 100%;
    font: 30px AirbnbCereal-Medium;
    color: #fff;
    text-align: left;
    line-height: 106px;
    padding-left: 30px;
}

.blog-videolist {
    margin-top: 0;
    margin-bottom: 40px;
    max-width: 1048px;
}

.blog-videolist h4 {
    font: 30px AirbnbCereal-Medium;
    color: #333;
}

.blog-videolist h4 i {
    background: url('../Img/blog_main_icon_bofang.png') no-repeat top center;
    display: inline-block;
    width: 23px;
    height: 24px;
    margin-right: 18px;
}

.blog-videolist-bg {
    background: white;
    border-radius: 5px;
    height: 376px;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 0px 20px 18px 40px;
    display: none;
}

.blog-videolist-bg::-webkit-scrollbar {
    width: 14px;
    height: 1px;
}

.blog-videolist-bg::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #e6e6e6;
}

.blog-videolist-bg::-webkit-scrollbar-track {
    background: #fff;
}

.blog-videolist-bg ul {
    text-align: center;
}

.blog-videolist-bg ul li {
    margin-top: 32px;
    margin-bottom: 14px;
}

.blog-videolist-bg ul li a {
    display: block;
    width: 249px;
    margin: 0 auto;
    text-decoration: none;
}

.blog-videolist-bg ul li a p {
    margin-bottom: 30px;
}

.blog-videolist-bg ul li a span {
    font: 18px AirbnbCereal-Medium;
    color: #000;
}

.blog-articallist {
    margin-bottom: 0px;
    max-width: 1048px;
}

.blog-articallist-bg {}

.blog-articallist-bg ul {
    margin: 0 -24px;
}

.blog-articallist-bg ul li {
    width: 50%;
    float: left;
    padding: 0 24px;
    padding-bottom: 58px;
}

.blog-articallist-bg ul li a {}

.blog-articallist-bg ul li a img {}

@media screen and (max-width: 900px) {
    .blog-articallist-bg ul li a {
        display: block;
    }
}

.blog-articallist-bg ul li h5 {
    font: 24px AirbnbCereal-Medium;
    color: #333;
    margin-top: 30px;
}

.blog-articallist-bg ul li p {
    font: 18px AirbnbCereal-Book;
    color: #666;
}

#tenant-list {
    margin-bottom: 22px;
}

/*google map*/
.pac-container {
    padding-left: 0px;
}

.pac-container:after {
    background-image: none !important;
    height: 0px;
}

/* hidden google logo */
.hdpi .pac-icon {
    background-image: url('../Img/location.png');
}

.pac-icon {
    float: left;
    margin-top: 10px;
    margin-left: 18px;
    margin-right: 11px;
    background: url('../Img/location.png') no-repeat;
    background-size: auto 100%;
    width: 16px;
}

.pac-item {
    color: #5f4b8b;
    display: block;
    font: 14px AirbnbCereal-Medium;
    padding-top: 11px;
    padding-bottom: 7px;
    line-height: 26px;
    cursor: pointer;
}

.pac-item-query {
    display: block;
    font: 16px AirbnbCereal-Medium;
    color: #333;
}

.pac-matched {}

/*QA0*/
#qa0 {
    margin-top: 130px;
}

#qa0 h3 {
    font: 36px AirbnbCereal-Book;
}

#qa0 small {
    font: 14px AirbnbCereal-Book;
    display: block;
    margin-top: 32px;
}

#qa0 .nav-btns {
    margin-top: 100px;
}

#qa0 .nav-btns .btn {
    font-family: AirbnbCereal-Medium;
    color: #fefefe;
    font-size: 18px;
    height: 60px;
    line-height: 34px;
    padding: 10px 24px 10px 24px;
    margin-top: 20px;
    margin-left: 12px;
    margin-right: 12px;
    background-color: #5f4b8b;
    color: #fefefe;
    border-radius: 10px;
}

#qa0 .nav-btns .btn i {
    margin-bottom: -6px;
}

#qa0 .nav-btns .btn span {
    vertical-align: middle;
}

#qa0 .nav-btns .btn:hover {
    background-color: #ff5a62;
}

#qa0 .nav-btns #want-tenant i {
    background: url('../Img/main_btn_search_opt.png') no-repeat center;
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 26px;
}

#qa0 .nav-btns #have-tenant i {
    background: url('../Img/main_btn_duigou_opt.png') no-repeat center;
    width: 26px;
    height: 26px;
    display: inline-block;
    margin-right: 22px;
    margin-bottom: -8px;
}

#qa0 p {
    font: 12px AirbnbCereal-Light;
    color: #666;
    margin-top: 30px;
}

#qa-progessbar {
    height: 14px;
    background-color: #9581bc;
}

#qa-progessbar span {
    background: #5f4b8b;
    display: inline-block;
    height: 14px;
}

#qa {
    margin-top: 148px;
}

#qa h3 {
    font: 36px AirbnbCereal-Book;
}

#qa #location {
    height: 64px;
    margin-top: 76px;
    margin-bottom: 76px;
    font: 18px AirbnbCereal-Book;
    padding: 20px;
    border: 2px solid #5f4b8b;
    border-radius: 10px;
}

#qa #qa-process-btns {
    display: inline-block;
}

#qa .btn {
    height: 60px;
    border-radius: 10px;
    padding-left: 100px;
    padding-right: 100px;
    margin: 10px;
}

#qa #back {
    border: 2px solid #9581bc;
    font: 18px AirbnbCereal-Book;
    /* color: #5f4b8b; */
    display: inline-block;
    line-height: 44px;
    float: left;
}

#qa #back {
    outline: none;
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#qa #back:hover {
    color: #ff5a62;
    border-color: #ff5a62;
}

#qa #next {
    background: #5f4b8b;
    font: 18px AirbnbCereal-Medium;
    color: #fefefe;
    line-height: 46px;
    float: right;
}

#qa #next {
    outline: none;
    -webkit-appearance: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

/* #qa #next:hover {
    background: #ff5a62;
} */

#qa #ValidError {
    display: inline-block;
    min-height: 80px;
    font: 18px AirbnbCereal-Book;
    color: #ff5a62;
}

#qa .text-danger {
    font: 18px AirbnbCereal-Book;
    color: #ff5a62;
}

#qa .sel-option-list {
    margin-top: 64px;
    margin-bottom: 30px;
}

#qa .sel-option-list li a {
    height: 64px;
    font: 18px AirbnbCereal-Book;
    color: #333;
    display: inline-block;
    line-height: 64px;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid rgba(149, 129, 188, 0.2);
    border-radius: 10px;
    margin-top: 14px;
    width: 674px;
}

#qa .sel-option-list .active a {
    color: #5f4b8b;
    border: 2px solid #5f4b8b;
    border-radius: 10px;
    font-family: AirbnbCereal-Medium;
}

#qa #sel-fee {}

#qa #sel-type {
    margin-top: 12px;
    margin-bottom: 10px;
}

#qa #sel-type a {
    margin: 27px 0 27px 0;
    text-decoration: none;
    cursor: pointer;
}

#qa #sel-type i {
    width: 90px;
    height: 90px;
    display: block;
    margin: 0 auto;
}

#qa #sel-type p {
    font: 18px AirbnbCereal-Medium;
    color: #333;
    line-height: 28px;
    margin-top: 15px;
}

#qa #sel-type .active i {
    border-radius: 100%;
    border: 2px solid #ff5a62;
}

#qa #sel-type #hd {
    background: url('../Img/QA/icon_h&d.png') no-repeat center;
    background-size: cover;
}

#qa #sel-type #au {
    background: url('../Img/QA/icon_a&u.png') no-repeat center;
    background-size: cover;
}

#qa #sel-type #town {
    background: url('../Img/QA/icon_town.png') no-repeat center;
    background-size: cover;
}

#qa #sel-type #villa {
    background: url('../Img/QA/icon_villa.png') no-repeat center;
    background-size: cover;
}

#qa #sel-type #ganny {
    background: url('../Img/QA/icon_ganny.png') no-repeat center;
    background-size: cover;
}

#qa #sel-type #boarding {
    background: url('../Img/QA/icon_boarding.png') no-repeat center;
    background-size: cover;
}

#qa #sel-number {
    margin-bottom: 70px;
}

#qa #sel-number>li {
    margin-top: 0px;
}

#qa #sel-number p {
    font: 18px AirbnbCereal-Medium;
    color: #333;
    line-height: 28px;
    margin-top: 15px;
}

#qa #sel-number .number-list {
    margin: 0 auto;
    margin-top: 24px;
    display: block;
    background: #fff;
    border: 2px solid #9581bc;
    border-radius: 10px;
    width: 70px;
    padding: 5px 7px 5px 7px;
}

#qa #sel-number .number-list li {
    border-bottom: 2px solid #9581bc;
}

#qa #sel-number .number-list li:last-child {
    border-bottom: 0;
}

#qa #sel-number .number-list li a {
    display: block;
    padding-top: 8px;
    padding-bottom: 8px;
    text-decoration: none;
    cursor: pointer;
}

#qa #sel-number .number-list li a span {
    display: block;
    height: 26px;
    width: 26px;
    line-height: 26px;
    margin: 0 auto;
    font: 18px AirbnbCereal-Book;
    color: #666;
    transition: all 0.3s;
}

#qa #sel-number .number-list li a:hover span {
    font: 18px AirbnbCereal-Bold;
    color: #fefefe;
    border-radius: 100%;
    background: #ff5a62;
    transition: all 0.3s;
}

#qa #sel-number .number-list .active a span {
    font: 18px AirbnbCereal-Bold;
    color: #fefefe;
    border-radius: 100%;
    background: #ff5a62;
}

#qa .weeklyrent-input {
    margin-top: 76px;
    margin-bottom: 76px;
}

#qa .weeklyrent-input .input-group-addon {
    font: 20px AirbnbCereal-Bold;
    color: #5f4b8b;
    background-color: transparent;
    border: 0;
    padding-left: 20px;
    padding-right: 20px;
}

#qa .weeklyrent-input #mobile-icon {
    padding-left: 22px;
    padding-right: 23px;
}

#qa .weeklyrent-input .qa-input {
    height: 64px;
    font: 20px AirbnbCereal-Bold;
    color: #333;
    padding: 20px;
    border: 2px solid #5f4b8b;
    border-radius: 10px;
}

#qa #sel-insurance {
    margin-bottom: 90px;
}

#qa #sel-timeframe {
    margin-bottom: 76px;
}

#qa #qa-info-form {
    margin-top: 54px;
}

#qa #qa-info-form .link-input {
    margin-top: 0px;
    margin-bottom: 0px;
}

#qa #qa-info-form .link-input .input-group-addon {
    padding-left: 17px;
    padding-right: 17px;
    background-color: #fff;
    border: 2px solid #5f4b8b;
    border-right: 0;
    border-radius: 10px 0 0 10px;
}

#qa #qa-info-form .link-input #mobile-icon {
    padding-left: 19px;
    padding-right: 20px;
}

#qa #qa-info-form .link-input .qa-input {
    font: 18px AirbnbCereal-Book;
    color: #999;
    height: 64px;
    border: 2px solid #5f4b8b;
    border-radius: 0 10px 10px 0;
}

#qa #submit-all {
    height: 70px;
    margin: 0;
    margin-top: 30px;
    border-radius: 10px;
    background: #5f4b8b;
    font: 18px AirbnbCereal-Medium;
    color: #fefefe;
    line-height: 28px;
}

#qa #submit-all:hover {
    background: #ff5a62;
}

#qa #last-back {
    height: 70px;
    margin: 0;
    margin-top: 14px;
    border-radius: 10px;
    border: 2px solid #9581bc;
    font: 18px AirbnbCereal-Book;
    color: #5f4b8b;
    line-height: 56px;
    background-color: transparent;
}

#qa #last-back:hover {
    color: #ff5a62;
    border-color: #ff5a62;
}

#report-header {
    margin-top: -15px;
}

#report-header div {}

#report-header #report-return-btn {
    float: left;
}

#report-header #report-return-btn a {
    font: 18px AirbnbCereal-Book;
    color: #666;
    line-height: 44px;
}

#report-header #report-return-btn a i {
    background: url('../Img/report_icon_right_slide.png') no-repeat center 0;
    width: 8px;
    height: 14px;
    display: inline-block;
    margin-right: 6px;
}

#report-header #report-complete-btn {
    float: right;
    display: none;
}

#report-header #report-complete-btn a {
    font: 14px AirbnbCereal-Medium;
    color: #fefefe;
    height: 44px;
    width: 292px;
    display: block;
    line-height: 44px;
    background: #5f4b8b;
    border-radius: 5px;
    text-align: center;
}

#report-container {
    margin-top: 40px;
}

#report-title {
    text-align: center;
}

#report-title h4 {
    font: 20px AirbnbCereal-Bold;
    color: #333;
}

#report-title h3 {
    font: 32px AirbnbCereal-Bold;
    color: #333;
    margin-bottom: 30px;
}

#report-title h3 span {
    color: #ff5a62;
}

#report-title h3 label {
    font: 24px AirbnbCereal-Book;
}

#report-title small {
    font: 18px AirbnbCereal-Book;
    color: #333;
    display: block;
}

#report-map {
    max-width: 1048px;
    height: 480px;
    margin-top: 44px;
}

#report-map iframe {
    width: 100%;
    height: 100%;
}

#report-body {
    margin-top: 88px;
    text-align: center;
}

#report-body h4 {
    font: 24px AirbnbCereal-Bold;
    color: #333;
}

#report-body p {
    font: 16px AirbnbCereal-Book;
    color: #333;
    margin-bottom: 0;
    margin-top: 8px;
}

#report-body ul {
    margin-top: 12px;
    margin-bottom: 0;
}

#report-body ul li {
    margin-top: 48px;
}

#report-body ul li div {
    height: 72px;
}

#report-tail {
    text-align: center;
    margin-top: 110px;
    margin-bottom: 158px;
}

#report-tail h4 {
    margin: 0;
    font: 24px AirbnbCereal-Bold;
    color: #333;
}

#report-tail ul {
    margin: 34px 64px 0 64px;
}

#report-tail ul li {
    margin-top: 26px;
}

#report-tail ul li .report-tail-bg {
    margin: 0 8px;
    background: #fff;
    padding: 28px;
    height: 260px;
    border-radius: 10px;
}

#report-tail ul li h5 {
    font: 18px AirbnbCereal-Medium;
    color: #333;
    margin-top: 24px;
    margin-bottom: 26px;
}

#report-tail ul li p {
    font: 18px AirbnbCereal-Book;
    color: #333;
}

/*mobile home page*/
@media screen and (max-width: 750px) {

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    i,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    button,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video {
        margin: 0;
        padding: 0;
        border: 0;
        vertical-align: baseline;
        outline: none;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section {
        display: block;
    }

    ol,
    ul {
        list-style: none;
    }

    button {
        background: transparent;
    }

    blockquote,
    q {
        quotes: none;
    }

    blockquote:before,
    blockquote:after,
    q:before,
    q:after {
        content: '';
        content: none;
    }

    strong {
        font-weight: bold;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    img {
        border: 0;
        max-width: 100%;
    }

    html {
        line-height: initial;
        overflow-x: hidden;
    }

    body {
        font-size: 0.32rem;
    }

    .navbar {
        height: auto;
    }

    #top-nav {
        height: 1.0rem;
        margin-bottom: 0;
    }

    .navbar-brand {
        padding: 0 0 0.12rem 0.26rem;
        height: auto;
    }

    .navbar-brand>img {
        /* width: 1.96rem; */
        transform: translateX(-40px);
    }

    .navbar-toggle {
        margin-top: 0.24rem;
        margin-right: 0.26rem;
        padding: 0.09rem 0.1rem;
    }

    .navbar-toggle .icon-bar+.icon-bar {
        margin-top: 0.12rem;
    }

    .navbar-default .navbar-toggle .icon-bar {
        width: 0.46rem;
        height: 0.04rem;
    }

    #banner-topborder {
        padding-top: 0.3rem;
    }

    #banner-container {
        background: #f2f2f2;
        min-height: 0;
    }

    #banner-container h3 {
        margin-top: 0.92rem;
        font-size: 0.46rem;
    }

    #banner-container p {
        margin-top: 0.54rem;
        font-size: 0.26rem;
    }

    #banner-container #banner-btns {
        margin-top: 0.63rem;
    }

    #banner-btns .btn {
        margin: 0.10rem 0 0.10rem 0;
        font-size: 0.26rem;
        height: 0.9rem;
        line-height: 0.34rem;
        padding: 0.23rem 0.44rem 0.23rem 0.44rem;
    }

    #banner-btns #find-tenant {}

    #banner-btns #find-tenant i {
        background: url('../Img/main_btn_search.png') no-repeat center 0;
        background-size: cover;
        width: 0.34rem;
        height: 0.33rem;
        display: inline-block;
        margin-right: 0.26rem;
        margin-bottom: -0.10rem;
    }

    #banner-btns #find-tenant:hover i {
        background: url('../Img/Phone/main_btn_search_opt.png') no-repeat center 0;
        background-size: auto 100%;
        width: 0.34rem;
        height: 0.33rem;
        display: inline-block;
        margin-right: 0.26rem;
        margin-bottom: -0.10rem;
    }

    #banner-btns #have-tenant {
        padding-right: 0.41rem;
    }

    #banner-btns #have-tenant i {
        background: url('../Img/Phone/main_btn_duigou.png') no-repeat center 0;
        background-size: auto 100%;
        width: 0.37rem;
        height: 0.26rem;
        display: inline-block;
        margin-right: 0.22rem;
        margin-bottom: -0.06rem;
    }

    #banner-btns #have-tenant:hover i {
        background: url('../Img/main_btn_duigou_opt.png') no-repeat center 0;
        background-size: auto 100%;
        width: 0.37rem;
        height: 0.26rem;
        display: inline-block;
        margin-right: 0.22rem;
        margin-bottom: -0.06rem;
    }

    #banner-container small {
        margin-top: 0.42rem;
        font-size: 0.20rem;
        padding: 0 1rem 0 1rem;
    }

    #main-container {
        background: #fff;
        border-radius: 0.05rem;
        padding: 0.34rem 0.5rem 0.34rem 0.5rem;
        text-align: center;
        margin-top: 0.8rem;
    }

    #main-container h3 {
        font-size: 0.30rem;
    }

    #main-container p {
        color: #999;
        margin-top: 0.14rem;
        margin-bottom: 0.38rem;
        font-size: 0.2rem;
    }

    #main-container .phone-main-pic {
        display: block;
        margin-bottom: 0.48rem;
    }

    #main-container .phone-main-pic img {
        margin: 0 auto;
    }

    #mainTab {
        display: none;
    }

    #mainTab-pointer {
        margin-top: 0.10rem;
    }

    #mainTab-pointer li {
        padding: 0;
    }

    #mainTab-pointer li a {
        padding: 0.10rem 0.16rem 0.10rem 0.16rem;
    }

    #mainTab-pointer li i {
        background-color: #f2f2f2;
        width: 0.14rem;
        height: 0.14rem;
        border-radius: 100%;
        display: inline-block;
    }

    #mainTab-pointer .active i {
        background-color: #ff5a62;
    }

    #mainTab-box {
        padding: 0;
    }

    #mainTabContent .tab-pane {
        background: #fff !important;
        overflow-x: visible !important;
        overflow-y: visible !important;
    }

    #mainTabContent .tab-pane .tab-pane-box {
        margin: 0;
        width: 100%;
        padding: 0;
    }

    #mainTabContent .tab-pane h4 {
        font-size: 0.28rem;
        display: inline;
    }

    #mainTabContent .tab-pane h5 {
        font-size: 0.28rem;
        display: inline;
        color: #5f4b8b;
    }

    #mainTabContent .tab-pane p {
        text-align: center;
        margin-top: 0.38rem;
        font-size: 0.24rem;
    }

    #main-ad-container {
        margin-top: 0.4rem;
    }

    #main-ad-container h4 {
        font: 0.30rem AirbnbCereal-Medium;
        color: #333;
    }

    #main-ad-container img {
        padding: 0 0.15rem;
    }


    #phone-container {
        margin-top: 0.38rem;
        text-align: center;
        max-width: 80% !important;
    }

    #phone-container .mobile-screen {
        position: relative;
        display: inline-block;
    }

    #phone-container .mobile-screen .mobile-bg {
        width: 4rem;
        height: 8rem;
    }

    #phone-container .mobile-screen .mobile-content {
        position: absolute;
        top: 0.20rem;
        left: 0.13rem;
        z-index: -1;
        width: 3.69rem;
        height: 7.58rem;
    }

    #phoneTabContent {
        width: 100%;
    }

    #phoneTab {
        display: block;
        width: 100%;
        margin: 0;
    }

    #phoneTab li {
        float: left;
        width: 50%;
        text-align: left;
    }

    #phoneTab a {
        font: 0.28rem AirbnbCereal-Medium;
        color: #999;
        padding: 0.07rem 0 0.07rem 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    #phoneTab span {
        width: 0.28rem;
        height: 0.28rem;
        margin-right: 0.1rem;
        display: inline-block;
    }

    #phoneTab .active span {}

    #phoneTab span i {
        width: 0.14rem;
        height: 0.14rem;
        background-size: auto 100%;
    }

    #phoneTab .active span i {
        width: 0.28rem;
        height: 0.28rem;
        border: 0.035rem solid #ff5a62;
        margin-bottom: -0.04rem;
    }

    #phoneTab a:hover>span>i {
        width: 0.28rem;
        height: 0.28rem;
        border: 0.035rem solid #ff5a62;
        margin-bottom: -0.04rem;
    }

    #phone-pointer {
        margin-top: 0;
    }

    #leasing-container {
        position: relative;
        margin-top: 2.60rem;
        margin-bottom: 0.4rem;
    }

    #leasing-container h3 {
        font-size: 0.30rem;
        margin-bottom: 0.3rem;
    }

    #leasing-container ul li {
        margin: 0.5rem 0;
        width: 100%;
    }

    #leasing-container ul li p {
        font-size: 0.22rem;
        margin-top: 0.40rem;
    }

    #leasing-container ul li p br {
        display: none;
    }

    #leasing-container #main-phone {
        background: url('../Img/Phone/main_phone.png') no-repeat center 0;
        background-size: auto 100%;
        width: 0.6rem;
        height: 0.59rem;
        display: block;
    }

    #leasing-container #main-phone:hover {
        background: url('../Img/Phone/main_phone.png') no-repeat center;
        background-size: auto 100%;
        width: 0.6rem;
        height: 0.59rem;
        display: block;
    }

    /* #leasing-container #main-wechat{background:url('../Img/Phone/main_wechat.png') no-repeat center 0;background-size: auto 100%;width:0.74rem;height:0.59rem;display:block;} */
    /* #leasing-container #main-wechat:hover{background:url('../Img/Phone/main_wechat.png') no-repeat center;background-size: auto 100%;width:0.74rem;height:0.59rem;display:block;} */
    #leasing-container #main-email {
        background: url('../Img/Phone/main_email.png') no-repeat center;
        background-size: auto 100%;
        width: 0.71rem;
        height: 0.53rem;
        display: block;
    }

    #leasing-container #main-email:hover {
        background: url('../Img/Phone/main_email.png') no-repeat center;
        background-size: auto 100%;
        width: 0.71rem;
        height: 0.53rem;
        display: block;
    }

    #leasing-container #main-visitus {
        background: url('../Img/Phone/main_visitus.png') no-repeat center;
        background-size: auto 100%;
        width: 0.60rem;
        height: 0.63rem;
        display: block;
    }

    #leasing-container #main-visitus:hover {
        background: url('../Img/Phone/main_visitus.png') no-repeat center;
        background-size: auto 100%;
        width: 0.60rem;
        height: 0.63rem;
        display: block;
    }

    footer.container-fluid {
        padding: 0;
    }

    footer #footer-nav-title {
        display: none;
    }

    footer .footer-nav {
        margin-top: 0;
        margin-bottom: 0.6rem;
        padding-bottom: 0.49rem;
        border-bottom: 0.02rem solid #eee;
    }

    footer .footer-nav ul {
        margin: 0;
        text-align: center;
    }

    footer .footer-nav li {
        margin-top: 0.71rem;
        float: none;
        width: 100%;
    }

    footer .footer-nav li:nth-child(1) {
        width: 100%;
    }

    footer .footer-nav li:nth-child(2) {
        width: 100%;
    }

    footer .footer-nav li:nth-child(3) {
        width: 100%;
    }

    footer .footer-nav li:nth-child(4) {
        width: 100%;
    }

    footer .footer-nav>ul>li>a {
        font-size: 0.24rem;
        padding: 0.17rem 0;
        margin: 0;
    }

    footer .footer-nav>ul>li>div>a {
        display: block;
        font: 0.26rem AirbnbCereal-Medium;
        margin: 0;
        padding-bottom: 0.27rem;
    }


    footer small {
        font: 0.20rem AirbnbCereal-Light;
        color: #fff;
        margin-top: 0.20rem;
        margin-bottom: 0.40rem;
    }

    footer #tail-link li {
        padding: 0;
    }

    footer #tail-link a {
        padding-left: 0.20rem;
        padding-right: 0.20rem;
        margin: 0;
    }

    footer #tail-link #youtube-logo {
        background: url('../Img/Phone/footer_youtube.png') no-repeat center;
        background-size: cover;
        width: 0.56rem;
        height: 0.56rem;
        display: block;
    }

    footer #tail-link #facebook-logo {
        background: url('../Img/NewImg/xhs.jpeg') no-repeat center;
        background-size: cover;
        width: 0.56rem;
        height: 0.56rem;
        display: block;
    }

    footer #tail-link #ins-logo {
        background: url('../Img/Phone/footer_ins.png') no-repeat center;
        background-size: cover;
        width: 0.56rem;
        height: 0.56rem;
        display: block;
    }

    /*property*/
    #property-header {
        margin-top: 0.92rem;
    }

    #property-header h3 {
        font-size: 0.46rem;
        width: 5.8rem;
        margin: 0 auto;
    }

    #property-header small {
        font-size: 0.18rem;
        display: block;
        margin-top: 0.38rem;
    }

    #property-header .text-danger {
        font-size: 0.16rem;
    }

    #property-header .input-group {
        height: 0.9rem;
        margin-top: 0.4rem;
        margin-bottom: 0.6rem;
        width: 6.9rem;
    }

    #property-header .input-group #location {
        font-size: 0.24rem;
        height: 0.9rem;
        width: 4.4rem;
    }

    #property-header .input-group #submit {
        height: 0.9rem;
        width: 2.5rem;
        font-size: 0.24rem;
    }

    #property-header ul {
        font-size: 0.2rem;
        padding: 0;
        margin: 0 0.2rem;
        text-align: left;
    }

    #property-header ul li img {
        margin-right: 0.02rem;
        width: 0.22rem;
        height: 0.22rem;
        margin-bottom: -0.02rem;
    }

    #property-header ul li {
        line-height: 0.22rem;
        height: 0.22rem;
        margin: 0;
        padding: 0;
        white-space: nowrap;
    }

    #property-header ul li:first-child {
        width: 37%;
    }

    #property-header ul li:nth-child(2) {
        width: 35%;
    }

    #property-header ul li:last-child {
        width: 25%;
        text-align: right;
    }

    #property-process {
        background: #fff;
        border-radius: 0.05rem;
        margin: 0.5rem 0.2rem 0 0.2rem;
        text-align: center;
        width: auto;
    }

    #property-process h3 {
        font-size: 0.30rem;
        padding-top: 0.34rem;
    }

    #property-process small {
        font-size: 0.20rem;
        margin-top: 0.20rem;
        margin-bottom: 0.38rem;
    }

    #property-process .phone-main-pic {
        display: block;
        padding: 0 0.1rem;
        max-width: 6.9rem;
        margin: 0 auto;
    }

    #property-process .phone-main-pic img {}

    #property-process-container {
        background-color: transparent;
    }

    #property-process-nav-box {
        width: 100%;
        padding: 0 0.15rem;
    }

    #property-process-nav-box ul {
        margin-top: 0.24rem;
        margin-bottom: 0.54rem;
    }

    #property-process-nav-box ul li {
        float: left;
    }

    #property-process-nav h4 {
        margin-top: 0;
        margin-bottom: 0;
    }

    #property-process-nav a {
        padding: 0;
    }

    #property-process-nav a>i {
        width: 0.2rem;
        height: 0.2rem;
        left: 0.08rem;
        top: 0;
    }

    #property-process-nav li:first-child>a>i {
        left: -0.1rem;
    }

    #property-process-nav li:last-child>a>i {
        top: 0;
    }

    #property-process-nav-box ul {
        padding-left: 0.2rem;
    }

    #property-process-nav-box ul li a h4 {
        margin-left: 0.18rem;
        margin-right: 0.18rem;
        font: 0.26rem AirbnbCereal-Medium;
        color: #999;
    }

    #property-process-nav-box ul li:first-child a h4 {
        margin-left: 0;
    }

    #property-process-nav-box ul li:last-child a h4 {
        margin-right: 0;
    }

    #property-process-nav .active h4 {
        color: #333;
    }

    #property-process-nav-box ul li p {
        display: none;
    }

    #property-process-box {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    #property-process-box .tab-pane {
        background: 0 !important;
        overflow-x: visible;
        overflow-y: visible;
    }

    #property-process-box .tab-pane .tab-pane-box {
        margin: 0;
        width: auto;
        height: auto;
        padding: 0;
    }

    #property-process-box .tab-pane h4 {
        font-size: 0.28rem;
    }

    #property-process-box .tab-pane p {
        text-align: left;
        margin-top: 0.38rem;
        font-size: 0.24rem;
        padding: 0 0.13rem;
        height: auto;
    }

    #property-process-box .tab-pane .tab-pane-box div {
        display: none;
    }

    #property-process-container .mainTab-pointer {
        margin-top: 0.1rem;
        margin-bottom: 0.32rem;
        display: block;
    }

    #property-process-container>div>.btn {
        display: block;
        margin: 0 auto;
        width: 4.6rem;
        height: 0.9rem;
        font: 0.24rem AirbnbCereal-Medium;
        color: #fefefe;
        background-color: #5f4b8b;
        border-radius: 10px;
        padding-top: 0.25rem;
        margin-bottom: 0.42rem;
    }

    #property-process-container>div>.btn:hover {
        background-color: #ff5a62;
    }

    #property-service {
        margin-top: 0.8rem;
    }

    #property-service h3 {
        font-size: 0.3rem;
    }

    #property-service small {
        margin: 0;
        margin-top: 0.29rem;
        margin-bottom: 0.62rem;
        font: 0.24rem AirbnbCereal-Book;
    }

    #property-service-box {
        padding: 0;
        margin: 0 0.2rem;
    }

    #property-service-box .property-service-bg {
        margin: 0;
        padding: 0;
        background-color: transparent;
    }

    #property-service-box ul {
        margin: 0 0.12rem;
    }

    #property-service-box ul li {
        padding: 0;
        width: 50%;
        float: left;
    }

    #property-service-box ul li img {
        width: 0.77rem;
    }

    #property-service-box ul li:nth-child(1)>div>img {
        content: url('../Img/Phone/pro_main_SK.png');
    }

    #property-service-box ul li:nth-child(2)>div>img {
        content: url('../Img/Phone/pro_main_New.png');
    }

    #property-service-box ul li:nth-child(3)>div>img {
        content: url('../Img/Phone/pro_main_Free.png');
    }

    #property-service-box ul li:nth-child(4)>div>img {
        content: url('../Img/Phone/pro_main_Leasing.png');
    }

    #property-service-box ul li:nth-child(5)>div>img {
        content: url('../Img/Phone/pro_main_propertym.png');
    }

    #property-service-box ul li:nth-child(6)>div>img {
        content: url('../Img/Phone/pro_main_Routine.png');
    }

    #property-service-box ul li:nth-child(7)>div>img {
        content: url('../Img/Phone/pro_main_Landlord.png');
    }

    #property-service-box ul li:nth-child(8)>div>img {
        content: url('../Img/Phone/pro_main_Rentreview.png');
    }

    #property-service-box h5 {
        height: auto;
        font: 0.24rem AirbnbCereal-Book;
        margin: 0.2rem 0 0.64rem 0;
    }

    #property-service-box p {
        text-align: left;
        font-size: 0.24rem;
    }

    #property-video {
        margin-top: 0.54rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }

    #property-video h3 {
        font-size: 0.3rem;
        text-align: center;
        padding-bottom: 0.44rem;
    }

    #property-video-box {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    #property-video-text {
        width: 100%;
        padding-right: 0;
    }

    #property-video-66 {
        display: none;
    }

    #property-video p {
        margin: 0.54rem 0.2rem 0.7rem 0.2rem;
        padding: 0;
        font-size: 0.24rem;
        line-height: 0.36rem;
    }

    #property-reason {
        margin: 0;
        margin-top: 0.52rem;
        margin-bottom: 0.9rem;
    }

    #property-reason h3 {
        font-size: 0.3rem;
        text-align: center;
        margin-bottom: 0.48rem;
    }

    #property-reason small {
        display: none;
    }

    #property-reason-box {
        margin-top: 0.14rem;
        text-align: center;
        background-color: transparent;
    }

    #property-reason-box #property-reason-left {
        display: none;
    }

    #property-reason-box #property-reason-right {
        width: 100%;
        float: none;
        padding: 0;
    }

    #property-reason-box li {
        margin-top: 0.28rem;
        width: 6.84rem;
        padding: 0;
    }

    #property-reason-box .property-reason-bg {
        padding: 0.36rem;
        background: #fff;
    }

    #property-reason-box ul {
        padding: 0;
    }

    #property-reason-box ul li img {
        width: 0.77rem;
    }

    #property-reason-box .property-reason-bg img {
        height: auto;
    }

    #property-reason-box ul li:nth-child(1)>div>div>img {
        content: url('../Img/Phone/pro_main_commitment.png');
    }

    #property-reason-box ul li:nth-child(2)>div>div>img {
        content: url('../Img/Phone/pro_main_expertise.png');
    }

    #property-reason-box ul li:nth-child(3)>div>div>img {
        content: url('../Img/Phone/pro_main_database.png');
    }

    #property-reason-box ul li:nth-child(4)>div>div>img {
        content: url('../Img/Phone/pro_main_Marketing.png');
    }

    #property-reason-box ul li:nth-child(5)>div>div>img {
        content: url('../Img/Phone/pro_main_Technologies.png');
    }

    #property-reason-box ul li:nth-child(6)>div>div>img {
        content: url('../Img/Phone/pro_main_results.png');
    }

    #property-reason-box h5 {
        font: 0.26rem AirbnbCereal-Medium;
        margin: 0.22rem 0 0.42rem 0;
    }

    #property-reason-box p {
        font-size: 0.24rem;
        line-height: 0.4rem;
        height: auto;
        min-height: 1rem;
    }

    #about-header {
        margin-top: 0.22rem;
        margin-bottom: 0.56rem;
        background-size: auto 100%;
        height: 1.91rem;
    }

    #about-header p {
        padding: 0 0.28rem
    }

    #about-header h3 {
        font-size: 0.46rem;
        margin-bottom: 0.34rem;
        padding-top: 0.88rem;
    }

    #about-header small {
        font-size: 0.22rem;
        display: block;
    }

    .about-box {
        margin-top: 0;
        margin-bottom: 0.94rem;
        padding: 0 0.28rem;
    }

    .about-pic {
        width: 100%;
        padding: 0;
        text-align: center;
    }

    .about-pic img {
        width: 6.92rem;
        height: auto;
    }

    .about-content {
        width: 100%;
        padding: 0 !important;
        float: left;
    }

    .about-box h4 {
        font-size: 0.3rem;
        margin-top: 0.24rem;
        margin-bottom: 0.6rem;
    }

    .about-box h4 img {
        margin-top: 0;
        margin-right: 0.16rem;
        margin-bottom: -0.16rem;
    }

    .about-box p {
        /* font: 0.24rem AirbnbCereal-Book; */
        line-height: initial;
        /* margin: 0 0.2rem; */
    }

    .about-box ul {
        padding-left: 0.4rem;
        list-style-type: disc;
        margin: 0 0.2rem;
    }

    .about-box li {
        font: 0.24rem AirbnbCereal-Book;
    }

    .about-box:nth-child(1) h4 img {
        content: url('../Img/Phone/about_main_mission.png');
        width: 0.52rem;
        height: 0.58rem;
    }

    .about-box:nth-child(2) h4 img {
        content: url('../Img/Phone/about_main_began.png');
        width: 0.46rem;
        height: 0.64rem;
    }

    .about-box:nth-child(3) h4 img {
        content: url('../Img/Phone/about_main_ourgoal.png');
        width: 0.62rem;
        height: 0.56rem;
    }

    #about-first-box {
        margin-top: 0;
        margin-bottom: 0.88rem;
    }

    #about-first-box h4 {
        margin-top: 0.18rem;
        margin-bottom: 0.36rem;
    }

    #about-first-box h4 img {
        content: url('../Img/Phone/about_main_about.png');
        width: 0.64rem;
        height: 0.58rem;
        margin-bottom: -0.16rem;
    }

    #google-map {
        margin-top: 1.52rem;
        margin-bottom: 0.86rem;
        line-height: 0;
        text-align: center;
    }

    #google-map h4 {
        font-size: 0.3rem;
        margin-bottom: 0.32rem;
    }

    #google-map small {
        font-size: 0.24rem;
    }

    #google-map img {
        content: url('../Img/Phone/about_main_map.png');
        margin-top: 0.52rem;
    }

    #about-tailer {
        display: none;
    }

    #contact-header {
        background: 0;
        min-height: 0;
        margin-top: 0;
    }

    #contact-header h3 {
        padding: 0;
        margin-top: 0.92rem;
        font-size: 0.46rem;
    }

    #contact-header small {
        margin-top: 0.22rem;
        font-size: 0.22rem;
    }

    #contact-touch {
        margin: 1.14rem 0.15rem 0 0.15rem;
        padding: 0.48rem 0.3rem 0.28rem 0.3rem;
        border-radius: 5px;
        width: auto;
    }

    #contact-touch h3 {
        font-size: 0.4rem;
    }

    #contact-touch small {
        font-size: 0.3rem;
        margin-top: 0.4rem;
        margin-bottom: 0.38rem;
    }

    #contact-touch .form-horizontal {
        width: 100%;
    }

    #contact-touch .form-group {
        margin-bottom: 0.2rem;
    }

    #contact-touch .text-danger {
        font-size: 0.24rem;
    }

    #contact-touch input#LastName {
        margin-top: 0.2rem;
    }

    #contact-touch input {
        height: 0.9rem;
        padding: 0.3rem;
        color: #999;
        font-size: 0.24rem;
    }

    #contact-touch input:focus {
        outline: none;
        border: 2px solid #5f4b8b;
        color: #333;
    }

    #contact-touch input::-webkit-input-placeholder {
        font: 0.24rem AirbnbCereal-Book;
    }

    #contact-touch input::-moz-placeholder {
        font: 0.24rem AirbnbCereal-Book;
    }

    #contact-touch input:-ms-input-placeholder {
        font: 0.24rem AirbnbCereal-Book;
    }

    #contact-touch input:-moz-placeholder {
        font: 0.24rem AirbnbCereal-Book;
    }

    #contact-touch textarea {
        height: 1.8rem;
        font-size: 0.24rem;
        padding: 0.34rem;
    }

    #contact-touch textarea::-webkit-input-placeholder {
        font: 0.24rem AirbnbCereal-Book;
    }

    #contact-touch textarea::-moz-placeholder {
        font: 0.24rem AirbnbCereal-Book;
    }

    #contact-touch textarea:-ms-input-placeholder {
        font: 0.24rem AirbnbCereal-Book;
    }

    #contact-touch textarea:-moz-placeholder {
        font: 0.24rem AirbnbCereal-Book;
    }

    #contact-touch a {
        height: 0.84rem;
        font-size: 0.24rem;
        line-height: 0.64rem;
        width: 100%;
    }

    #contact-touch a i {
        width: 0.36rem;
        height: 0.36rem;
        background-size: cover;
    }

    #contact-touch .form-group ul {
        width: 100%;
        margin: 0;
    }

    #contact-touch .form-group ul li {
        width: 1.86rem;
        margin: 0;
        padding: 0;
    }

    #contact-touch .form-group ul li:first-child {
        margin-right: 0.4rem;
    }

    #contact-touch .form-group ul li:last-child {
        margin-left: 0.4rem;
    }

    .sel-role i {
        width: 1.56rem;
        height: 1.56rem;
        background-size: cover !important;
    }

    .sel-role p {
        font-size: 0.24rem;
    }

    #contact-touch .sel-exist {
        height: 0.6rem;
        width: 3.06rem;
        font: 0.24rem AirbnbCereal-Book;
        border-radius: 0.3rem;
        line-height: 0.6rem;
        margin: 0 auto;
        padding: 0;
        margin-bottom: 0.32rem;
    }

    #contact-touch .sel-exist .pre {
        background: url('../Img/Phone/pho_contact_guanbi.png') no-repeat center 0;
        background-size: cover;
        width: 0.5rem;
        height: 0.5rem;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0.05rem;
        margin-left: -0.5rem;
    }

    #contact-touch .sel-exist span {
        padding: 0 0.05rem;
    }

    #contact-touch .sel-exist-checked {
        font: 0.24rem AirbnbCereal-Medium;
        color: #ff5a62;
        background: rgba(255, 90, 98, 0.2);
        line-height: 0.6rem;
        padding: 0;
        border: 0;
    }

    #contact-touch .sel-exist-checked .pre {
        display: none;
    }

    #contact-touch .sel-exist-checked .post {
        background: url('../Img/Phone/pho_contact_duigou.png') no-repeat center 0;
        background-size: cover;
        width: 0.5rem;
        height: 0.5rem;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0.05rem;
        margin-right: -0.3rem;
    }

    #contact-info {
        margin-top: 0.8rem;
        margin-bottom: 0.7rem;
        background: 0;
        width: 100%;
    }

    #contact-map {
        width: 7.5rem;
        height: 6.38rem;
        float: none;
    }

    #contact-text {
        margin-top: 0.72rem;
        margin-left: 0;
        margin-bottom: 0.72rem;
        padding: 0;
        text-align: center;
        float: none;
        width: 100%;
    }

    #contact-text dt {
        margin-top: 0.48rem;
        font-size: 0.28rem;
    }

    #contact-text dd {
        margin-top: 0.22rem;
        font: 0.24rem AirbnbCereal-Book;
    }

    #blog-header {
        margin-top: 0.22rem;
        height: 1.8rem;
        margin-bottom: 0.54rem;
        background-size: auto 100%;
        min-height: 138px;
        margin-bottom: 80px;
    }

    #blog-header h3 {
        padding-top: 0.46rem;
        font-size: 0.46rem;
    }

    #blog-header small {
        margin-top: 0.22rem;
        font-size: 0.22rem;
    }

    #blog-slider {
        margin-bottom: 0.54rem;
    }

    #blog-slider .carousel-indicators {
        bottom: 0.8rem;
    }

    #blog-slider .carousel-indicators li {
        width: 0.1rem;
        height: 0.1rem;
        background-color: rgba(0, 0, 0, 0.5);
        border: 0;
        margin: 0 0.14rem;
    }

    #blog-slider .carousel-indicators .active {
        width: 0.1rem;
        height: 0.1rem;
        background-color: #ff5a62;
        margin: 0 0.14rem;
    }

    #blog-slider .carousel-caption {
        bottom: 0;
        height: 0.78rem;
        padding: 0;
        background-color: rgba(0, 0, 0, 0.5);
        left: 0;
        right: 0;
    }

    #blog-slider .carousel-caption a {
        display: block;
        width: 100%;
        height: 100%;
        font: 0.24rem AirbnbCereal-Medium;
        color: #fff;
        text-align: left;
        line-height: 0.78rem;
        padding-left: 0.24rem;
    }

    .blog-videolist {
        margin-left: 0.28rem;
        margin-right: 0.28rem;
        margin-bottom: 0.4rem;
    }

    .blog-videolist h4 {
        font-size: 0.3rem;
    }

    .blog-videolist h4 i {
        width: 0.23rem;
        height: 0.24rem;
        margin-right: 0.18rem;
    }

    .blog-articallist {
        padding-left: 0.28rem;
        padding-right: 0.28rem;
        margin-bottom: 0.26rem;
    }

    .blog-articallist-bg ul {
        margin: 0 -0.16rem;
    }

    .blog-articallist-bg ul li {
        padding: 0 0.16rem 0.38rem 0.16rem;
    }

    .blog-articallist-bg ul li a img {
        height: auto;
    }

    .blog-articallist-bg ul li h5 {
        font-size: 0.24rem;
        margin-top: 0.2rem;
    }

    #tenant-list {
        margin-bottom: 0.26rem;
    }

    /*google map*/
    .pac-container {
        padding-left: 0;
    }

    .pac-container:after {
        background-image: none !important;
        height: 0;
    }

    /* hidden google logo */
    .hdpi .pac-icon {
        background-image: url('../Img/location.png');
    }

    .pac-icon {
        float: left;
        margin-top: 0.1rem;
        margin-left: 0.18rem;
        margin-right: 0.11rem;
        background: url('../Img/location.png') no-repeat;
        background-size: 0.16rem;
    }

    .pac-item {
        color: #ff5a62;
        display: block;
        font: 0.14rem AirbnbCereal-Medium;
        padding-top: 0.11rem;
        padding-bottom: 0.07rem;
        line-height: 0.2rem;
    }

    .pac-item-query {
        display: block;
        font: 0.1rem AirbnbCereal-Medium;
        color: #333;
    }

    .pac-matched {}

    #qa0 {
        margin-top: 1.06rem;
    }

    #qa0 h3 {
        font: 0.34rem AirbnbCereal-Medium;
        color: #333;
    }

    #qa0 small {
        font-size: 0.26rem;
        margin-top: 0.32rem;
    }

    #qa0 .nav-btns {
        margin-top: 2rem;
    }

    #qa0 .nav-btns .btn {
        font: 0.3rem AirbnbCereal-Medium;
        width: 6.4rem;
        height: 0.9rem;
        line-height: 0.8rem;
        margin: 0.2rem 0;
        padding: 0;
        border-radius: 0.1rem;
    }

    #qa0 .nav-btns #want-tenant i {
        width: 0.24rem;
        height: 0.24rem;
        background-size: cover;
        margin-right: 0.26rem;
        margin-bottom: -0.04rem;
    }

    #qa0 .nav-btns #have-tenant i {
        width: 0.26rem;
        height: 0.26rem;
        background-size: cover;
        margin-right: 0.22rem;
        margin-bottom: -0.04rem;
    }

    #qa0 p {
        font-size: 0.18rem;
        margin-top: 0.3rem;
    }

    #qa {
        margin-top: 1.06rem;
    }

    #qa h3 {
        font: 0.34rem AirbnbCereal-Medium;
        color: #333;
    }

    #qa small {
        font-size: 0.24rem;
    }

    #qa #location {
        height: 0.9rem;
        width: 6.74rem;
        margin: 0 auto;
        padding: 0.2rem;
        margin-top: 0.66rem;
        margin-bottom: 4.12rem;
        font-size: 0.24rem;
        color: #333;
        border: 0.02rem solid #5f4b8b;
        border-radius: 0.1rem;
    }

    #qa .btn {
        height: 0.9rem;
        width: 6.4rem;
        border-radius: 0.1rem;
        padding: 0;
        margin: 0.2rem;
    }

    #qa #next {
        font-size: 0.30rem;
        float: initial;
        line-height: initial;
    }

    #qa #back {
        font-size: 0.24rem;
        border: 0.02rem solid #9581bc;
        line-height: 0.9rem;
        float: none;
    }

    #qa .sel-option-list {
        margin-top: 0.50rem;
        margin-bottom: 0.87rem;
    }

    #qa .sel-option-list li a {
        font: 0.26rem AirbnbCereal-Book;
        color: #333;
        width: 6.74rem;
        height: 0.9rem;
        line-height: 0.9rem;
        padding: 0;
        border: 0.02rem solid rgba(149, 129, 188, 0.2);
        border-radius: 0.1rem;
        margin-top: 0.16rem;
    }

    #qa .sel-option-list .active a {
        line-height: 0.9rem;
        font-size: 0.26rem;
        border: 0.02rem solid #5f4b8b;
        border-radius: 0.1rem;
        font-family: AirbnbCereal-Medium;
    }

    #qa #sel-type {
        margin-top: 0.12rem;
        margin-bottom: 1.47rem;
    }

    #qa #sel-type li {
        width: 33.3333%;
        float: left;
    }

    #qa #sel-type a {
        margin: 0.27rem 0;
    }

    #qa #sel-type i {
        width: 0.9rem;
        height: 0.9rem;
    }

    #qa #sel-type .active i {
        border: 0.02rem solid #ff5a62
    }

    #qa #sel-type p {
        font-size: 0.26rem;
        line-height: initial;
        margin-top: 0.18rem;
    }

    #qa #sel-type .active p {
        font-size: 0.26rem;
        height: initial;
    }

    #qa #sel-number {
        margin-bottom: 1.14rem;
    }

    #qa #sel-number>li {
        width: 33.3333%;
        float: left;
        margin-top: 0.34rem;
    }

    #qa #sel-number img {
        width: 0.9rem;
        height: 0.9rem;
    }

    #qa #sel-number p {
        font-size: 0.26rem;
        line-height: initial;
        margin-top: 0.12rem;
    }

    #qa #sel-number .number-list {
        margin-top: 0.34rem;
        border: 0.02rem solid #9581bc;
        border-radius: 0.1rem;
        width: 0.9rem;
        padding: 0 0.16rem;
    }

    #qa #sel-number .number-list li {
        border-bottom: 0.02rem solid #9581bc;
    }

    #qa #sel-number .number-list li a {
        padding: 0.06rem 0.02rem;
    }

    #qa #sel-number .number-list li a span {
        width: 0.48rem;
        height: 0.48rem;
        line-height: 0.48rem;
        font: 0.28rem AirbnbCereal-Book;
        padding-top: 0.04rem;
    }

    #qa #sel-number .number-list .active a span {
        font: 0.30rem AirbnbCereal-Bold;
        padding-top: 0.02rem;
    }

    #qa #sel-number .number-list li a:hover span {
        font: 0.30rem AirbnbCereal-Bold;
        padding-top: 0.02rem;
    }

    #qa .qa-input {
        height: 0.9rem;
        font-size: 0.24rem;
        padding: 0.2rem;
        border: 0.02rem solid #5f4b8b;
        border-radius: 0.1rem;
    }

    #qa .qa-input:focus {
        -webkit-box-shadow: inset 0 0.01rem 0.01rem rgba(0, 0, 0, .075), 0 0 0.08rem rgba(102, 175, 233, .6);
        -moz-box-shadow: inset 0 0.01rem 0.01rem rgba(0, 0, 0, .075), 0 0 0.08rem rgba(102, 175, 233, .6);
    }

    #qa .weeklyrent-input {
        width: 6.74rem;
        margin: 0 auto;
        margin-top: 0.66rem;
        margin-bottom: 4.02rem;
    }

    #qa .weeklyrent-input .input-group-addon {
        font-size: 0.24rem;
        padding-left: 0.2rem;
        padding-right: 0.2rem;
        border: 0;
        background-color: transparent;
    }

    #qa .weeklyrent-input .qa-input {
        height: 0.9rem;
        font-size: 0.24rem;
        padding: 0.2rem;
        border: 0.02rem solid #5f4b8b;
        border-radius: 0.1rem;
    }

    #qa #sel-insurance {
        margin-top: 0.66rem;
        margin-bottom: 1.77rem;
    }

    #qa #sel-insurance li a {
        width: 7.2rem;
    }

    #qa #sel-timeframe {
        margin-top: 0.66rem;
        margin-bottom: 1.47rem;
    }

    #qa #qa-info-form {
        margin-top: 0.66rem;
    }

    #qa .link-input {
        width: 6.74rem;
        margin: 0 auto;
        margin-top: 0.66rem;
        margin-bottom: 4.02rem;
    }

    #qa #qa-info-form .input-group-addon {
        display: none;
    }

    #qa #qa-info-form .link-input .qa-input {
        height: 0.9rem;
        font-size: 0.24rem;
        padding: 0.2rem;
        border: 0.02rem solid #5f4b8b;
        border-radius: 0.1rem;
    }

    #qa #qa-info-form .qa-input:focus {
        -webkit-box-shadow: inset 0 0.01rem 0.01rem rgba(0, 0, 0, .075), 0 0 0.08rem rgba(102, 175, 233, .6);
        -moz-box-shadow: inset 0 0.01rem 0.01rem rgba(0, 0, 0, .075), 0 0 0.08rem rgba(102, 175, 233, .6);
    }

    #qa #submit-all {
        height: 0.9rem;
        margin-top: 1.47rem;
        font-size: 0.24rem;
        border-radius: 0.1rem;
        line-height: initial;
        padding: 0.11rem 0;
    }

    #qa #last-back {
        height: 0.9rem;
        font-size: 0.24rem;
        border: 0.02rem solid #9581bc;
        line-height: 0.9rem;
        float: none;
        border-radius: 0.1rem;
    }

    #qa #ValidError {
        font-size: 0.24rem;
        min-height: 0.8rem;
    }

    #report-header {
        margin-top: -0.15rem;
        margin-left: 0.3rem;
    }

    #report-header #report-return-btn a {
        font: 0.18rem AirbnbCereal-Book;
        color: #666;
    }

    #report-container {
        margin-top: 0.5rem;
    }

    #report-title {}

    #report-title h4 {
        font: 0.2rem AirbnbCereal-Medium;
        color: #333;
    }

    #report-title h3 {
        font: 0.36rem AirbnbCereal-Medium;
        max-width: 6rem;
        margin: 0 auto;
        margin-top: 0.38rem;
        margin-bottom: 0.6rem;
    }

    #report-title h3 span {
        display: block;
    }

    #report-title h3 br {
        display: none;
    }

    #report-title h3 label {
        font: 0.36rem AirbnbCereal-Medium;
    }

    #report-title small {
        font-size: 0.2rem;
        margin: 0 0.2rem;
    }

    #report-title small br {
        display: none;
    }

    #report-body {
        margin-top: 0.74rem;
    }

    #report-body h4 {
        font: 0.24rem AirbnbCereal-Medium;
    }

    #report-body ul {
        margin: 0.18rem 0.5rem 0 0.5rem;
    }

    #report-body ul li {
        margin-top: 0.52rem;
        width: 50%;
        float: left;
    }

    #report-body ul li div {
        height: 0.7rem;
    }

    #report-body ul li img {
        height: 0.5rem;
    }

    #report-body p {
        margin-top: 0.06rem;
        font-size: 0.2rem;
    }

    #report-body ul li:nth-child(3) p br {
        display: none;
    }

    #report-tail {
        margin-top: 1.48rem;
        margin-bottom: 0.94rem;
    }

    #report-tail h4 {
        font: 0.24rem AirbnbCereal-Medium;
        margin-bottom: 0.4rem;
    }

    #report-tail ul {
        margin: 0 auto;
        width: 6.88rem;
        background: #fff;
        padding-bottom: 0.2rem;
    }

    #report-tail ul li {
        margin-top: 0.4rem;
        margin-bottom: 0.22rem;
    }

    #report-tail ul li .report-tail-bg {
        margin: 0;
        padding: 0;
    }

    #report-tail ul li h5 {
        margin-top: 0.1rem;
        margin-bottom: 0.28rem;
        font-size: 0.24rem;
    }

    #report-tail ul li p {
        font-size: 0.2rem;
        margin: 0 1.2rem;
    }
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.header-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #5F4B8B;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    opacity: 0.96;
}

.header-icon-long {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 39, 65);
    border-radius: 90px;
    width: 55px;
    height: 35px;
    overflow: hidden;
    opacity: 0.94;
}


.mobile-tab-item:hover {
    opacity: 1;
}

.mobile-tab-item {
    background-color: #5f4b8b !important;
    color: #fff !important;
    padding: 8px 8px 8px 20px !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    margin-bottom: 10px;
    margin-right: 5px;
    font: 0.2rem AirbnbCereal-book !important;
    opacity: 0.8;
}

.mobile-content-tab-container {
    max-width: 800px;
}

@media (min-width: 768px) {
    .mobile-content-tab-container {
        border: 1px solid #ccc;
        padding: 24px 80px 24px 200px;
        box-shadow: 0 4px 28px 0 rgba(0, 0, 0, 0.2), 0px 6px 40px 20px rgba(0, 0, 0, 0.09);
        margin: 10px;
        border-radius: 20px;
        box-sizing: content-box;
        position: relative;
    }

    .mobile-tab-item {
        background-color: #5f4b8b !important;
        color: #fff !important;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        border-radius: 15px 5px 8px 8px;
        width: 300px;
        margin-bottom: 8px;
        font: 0.17rem AirbnbCereal-book !important;
        padding: 8px 8px 8px 25px !important;
    }

    .mobile-tab-container {
        position: relative;
        top: -493px;
        left: -90px;
        height: 0;
    }
}

.contact-icon {
    color: #5f4b8b
}

.contact-icon:hover {
    color: #EC6567;
}


.contact-container {
    max-width: 1100px !important;
}

.tab-title {
    line-height: 35px !important;
}

.tab-content {
    /* margin-top: 98px !important; */
}

.responsive-img {
    width: 100%;
    max-width: 140px;
}

#footer-last-icon-wrap {
    overflow: hidden;
}


#footer-last-icon-img {
    padding-bottom: 2px;
}

@media (max-width: 768px) {
    .responsive-img {
        max-width: 70px;
    }

    #footer-last-icon-img {
        transform: scale(9);
        padding-bottom: .75px;
    }

    #office-section {
        /* padding-left: 20%;
        width: 80%;
        text-align: left; */
        display: none;
    }
}