.suburb-input {
    max-width: 1000px;
}

.qa-selected {
    border: 2px solid #ff5a62;
    border-radius: 100%;
}

.frame-selected {
    border: 2px solid #542e91 !important;
}

.progress-bar {
    width: 100%;
    background-color: #9581bc;
    overflow: hidden;
}

.progress-bar-fill {
    height: 14px;
    background-color: #542e91;
    transition: width 0.3s ease-in-out;
}

.valid-error {
    display: inline-block;
    min-height: 80px;
    font: 18px AirbnbCereal-Book;
    color: #ff5a62;
}

.banner-topborder {
    border-top: 2px solid #542e91;
    padding-top: 30px;
}

.insurance-selection-box {
    height: 64px;
    font: 18px AirbnbCereal-Book;
    color: #333;
    display: inline-block;
    line-height: 64px;
    text-decoration: none;
    cursor: pointer;
    border: 2px solid rgba(149, 129, 188, .2);
    border-radius: 10px;
    margin-top: 14px;
}

@media screen and (max-width: 600px) {
    .insurance-selection-box {
        font-size: 0.26rem;
        width: 350px;
    }
}

@media (max-width: 600px) {
    .pac-item {
        font-size: 12px;
        color: #675591;
        font-weight: 300;
    }
    .pac-item-query {
        font-size:11px;
        margin-bottom: 3px;
    }
}

.insurance-selected {
    border: 2px solid #542e91;
}
